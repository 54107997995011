import case1 from '../images/Cases/case-1/case-1-img.png'
import logo1 from '../images/Cases/case-1/logo-1.svg' 
import logo2 from '../images/Cases/case-1/logo-2.svg' 
import openinnovations from '../images/Cases/case-1/open.svg'
import skolkovo from '../images/Cases/case-1/skolkovo.svg'
import arrow from '../images/Cases/case-1/arrow.svg'
import pepsico from '../images/Cases/case-3/pepsico.svg'
import russianLogo from '../images/Cases/case-4/russian-logo.svg'
import tv from '../images/Cases/case-4/tv.svg'
import speakerLogo from '../images/Cases/case-4/speaker-logo.svg'
import business from '../images/Cases/case-5/business.svg'
import exportCenter from '../images/Cases/case-5/export.svg'
import logo1Export from '../images/Cases/case-5/logo-1.svg'
import logo2Export from '../images/Cases/case-5/logo-2.svg'
import logoYandex from '../images/Cases/case-6/logo.svg'
import logo1Bdsr from '../images/Cases/case-7/logo-1.svg'
import logo2Bdsr from '../images/Cases/case-7/logo-2.svg'
import logoX5 from '../images/Cases/case-8/logo.svg'
import village from '../images/Cases/case-9/village.svg'
import kaspersky from '../images/Cases/case-10/kaspersky.svg'
import logo1Village from '../images/Cases/case-9/logo-1.svg'
import logo2Village from '../images/Cases/case-9/logo-2.svg'
import sheremetyevo from '../images/Cases/case-12/logo.svg'
import educationSalon from '../images/Cases/case-13/logo.svg'
import educationSalonOrg from '../images/Cases/case-13/logo-org.svg'
import avon from '../images/Cases/case-14/logo.svg'
import education from '../images/Cases/case-15/logo.svg'
import infourok from '../images/Cases/case-16/logo.svg'
import infourokAuthor1 from '../images/Cases/case-16/author-1.png'
import infourokAuthor2 from '../images/Cases/case-16/author-2.png'
import infourokAuthor3 from '../images/Cases/case-16/author-3.png'
import skolkovoCase from '../images/Cases/case-17/logo.svg'
import bitrix from '../images/Cases/case-18/logo.svg'
import skOpen from '../images/Services/b-1/sk-open.svg'
import arrowRight from '../images/Services/arrow.svg'
import chukotkaTagsIcon from '../images/Cases/chukotka-tags-icon.svg'
import chukotkaCaseImg2 from '../images/Cases/chukotka-case-img-2.png'
import frutonyanyaCaseImg2 from '../images/Cases/frutonyanya-case-img-2.png'
import moskovskayaNedelya from '../images/Cases/moskovskayaNedelya.svg'
import frutonyanya from '../images/Cases/frutonyanya.svg'
import chukotka from '../images/Cases/chukotka.svg'
import frutonyanyaLogo1 from '../images/Cases/frutonyanya-logo-1.png'
import frutonyanyaLogo2 from '../images/Cases/frutonyanya-logo-2.svg'
import frutonyanyaLogo3 from '../images/Cases/frutonyanya-logo-3.svg'
import frutonyanyaLogos4_1 from '../images/Cases/frutonyanya-logos-4_1.png'
import frutonyanyaLogos4_2 from '../images/Cases/frutonyanya-logos-4_2.png'
import moskovskayaNedelyaLogo1 from '../images/Cases/moskovskayaNedelya-logo-1.png'
import moskovskayaNedelyaLogo2 from '../images/Cases/moskovskayaNedelya-logo-2.svg'
import moskovskayaNedelyaCaseImg2 from '../images/Cases/moskovskayaNedelya-case-img-2.png'


import speaker1 from '../images/Cases/case-4/speaker-1.png'
import speaker2 from '../images/Cases/case-4/speaker-2.png'
import speaker3 from '../images/Cases/case-4/speaker-3.png'
import speaker4 from '../images/Cases/case-4/speaker-4.png'
import speaker5 from '../images/Cases/case-4/speaker-5.png'

import speaker1Nobel from '../images/Cases/case-11/speaker-1.png'
import speaker2Nobel from '../images/Cases/case-11/speaker-2.png'
import speaker3Nobel from '../images/Cases/case-11/speaker-3.png'


import avatarBdsr1 from '../images/Cases/case-7/bdsr1.png'
import avatarBdsr2 from '../images/Cases/case-7/bdsr2.png'
import avatarBdsr3 from '../images/Cases/case-7/bdsr3.png'
import avatarBdsr4 from '../images/Cases/case-7/bdsr4.png'
import avatarBdsr5 from '../images/Cases/case-7/bdsr5.png'
import avatarBdsr6 from '../images/Cases/case-7/bdsr6.png'
import avatarBdsr7 from '../images/Cases/case-7/bdsr7.png'


import cs1 from '../images/Cases/cs-1.png'
import cs2 from '../images/Cases/cs-2.png'
import cs3 from '../images/Cases/cs-3.svg'
import cs4 from '../images/Cases/cs-4.svg'
import cs5 from '../images/Cases/cs-5.svg'
import cs6 from '../images/Cases/cs-6.svg'
import cs7 from '../images/Cases/cs-7.svg'
import cs8 from '../images/Cases/cs-8.svg'
import cs9 from '../images/Cases/cs-9.svg'
import cs10 from '../images/Cases/cs-10.svg'
import cs11 from '../images/Cases/cs-11.svg'
import cs12 from '../images/Cases/cs-12.svg'

import forbes from '../images/Cases/case-2/forbes.svg'

const csp1 = {
    case1 , 
    logo1, 
    logo2, 
    openinnovations, 
    skolkovo, 
    arrow,
    chukotka,
    frutonyanya,
    moskovskayaNedelya,
    frutonyanyaLogo1,
    frutonyanyaLogo2,
    frutonyanyaLogo3,
    moskovskayaNedelyaLogo1,
    moskovskayaNedelyaLogo2,
}

const csp2 = {
    forbes,
    pepsico,
    russianLogo,
    tv,
    speakerLogo,
    speaker1,
    speaker2,
    speaker3,
    speaker4,
    speaker5,
    business,
    exportCenter,
    logo1Export,
    logo2Export,
    logoYandex,
    logo1Bdsr,
    logo2Bdsr,
    avatarBdsr1,
    avatarBdsr2,
    avatarBdsr3,
    avatarBdsr4,
    avatarBdsr5,
    avatarBdsr6,
    avatarBdsr7,
    logoX5,
    village,
    logo1Village,
    logo2Village,
    kaspersky,
    speaker1Nobel,
    speaker2Nobel,
    speaker3Nobel,
    sheremetyevo,
    educationSalon,
    educationSalonOrg,
    avon,
    education,
    infourok,
    infourokAuthor1,
    infourokAuthor2,
    infourokAuthor3,
    skolkovoCase,
    bitrix,
    skOpen,
    arrowRight,
    chukotkaTagsIcon,
    chukotkaCaseImg2,
    frutonyanyaCaseImg2,
    frutonyanyaLogos4_1,
    frutonyanyaLogos4_2,
    moskovskayaNedelyaCaseImg2,
}

const caseBrand = {
    cs1, 
    cs2, 
    cs3, 
    cs4, 
    cs5, 
    cs6, 
    cs7, 
    cs8, 
    cs9, 
    cs10, 
    cs11, 
    cs12,
}

export {caseBrand, csp1, csp2}
